<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考试配置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="考试名称" class="searchboxItem ci-full"><span class="itemLabel">考试名称:</span>
              <div style="font-size:14px">{{examinationInfo.examinationName}}</div>
            </div>
            <div title="考试科目" class="searchboxItem ci-full"><span class="itemLabel">考试科目:</span>
              <div style="font-size:14px">{{$setDictionary('EA_EXAM_SUBJECT_CODE',examinationInfo.subjectCode)}}</div>
            </div>
            <div title="试卷分数" class="searchboxItem ci-full"><span class="itemLabel">试卷分数:</span>
              <div style="font-size:14px">{{examinationInfo.totalScore}}</div>
            </div>
          </div>
        </div>
        <el-container>
          <el-main>
            <el-form ref="form" :model="examinationConfigInfo" size="mini" label-width="140px" :rules="rules">
              <div class="flex">
                <h3>基本设置</h3>
                <el-form-item label="考试及格分数线" prop="passScore">
                  <el-input-number @change="numberChange($event,'passScore')" v-model="examinationConfigInfo.passScore" size="mini" :precision="2" :controls="false" :min="0" :max="examinationInfo.totalScore"></el-input-number> 分
                </el-form-item>
                <el-form-item label="试卷题目乱序" prop="questionRandom">
                  <el-switch v-model="examinationConfigInfo.questionRandom" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
                <el-form-item label="多选题计分方式" prop="multiSelectType">
                  <el-radio-group v-model="examinationConfigInfo.multiSelectType">
                    <el-radio label="0">全部答对才得分</el-radio>
                    <el-radio label="1">答对部分按比例给分</el-radio>
                  </el-radio-group>
                  <template v-if="examinationConfigInfo.multiSelectType==='1'">
                    <el-input-number @change="numberChange($event,'multiScore')" v-model="examinationConfigInfo.multiScore" size="mini" :precision="0" :controls="false" :min="0" :max="100"></el-input-number> %
                  </template>
                </el-form-item>
                <el-form-item label="学员交卷时间限制" prop="submitTime">
                  <el-switch v-model="examinationConfigInfo.submitTime" active-color="#13ce66" inactive-color="#ff4949" />
                  <template v-if="examinationConfigInfo.submitTime">
                    考试开始后最短
                    <el-input-number @change="numberChange($event,'minSubmit')" v-model="examinationConfigInfo.minSubmit" size="mini" :precision="0" :controls="false" :min="1"></el-input-number>
                    分钟后允许学员交卷
                  </template>
                </el-form-item>
                <el-form-item label="考试开始前" prop="submitTime">
                  <el-input-number @change="numberChange($event,'advanceEnterTime')" v-model="examinationConfigInfo.advanceEnterTime" size="mini" :precision="0" :controls="false" :min="15"></el-input-number> 分钟允许学员进入等待页，进行人脸认证
                </el-form-item>
                <el-form-item label="考试开始后" prop="lateJoinTime">
                  <template>
                    <el-input-number @change="numberChange($event,'lateJoinTime')" v-model="examinationConfigInfo.lateJoinTime" size="mini" :precision="0" :controls="false" :min="1"></el-input-number>
                    分钟内允许学员参加考试(超过此时间段进入考试即算迟到)
                  </template>
                </el-form-item>
                <el-form-item label="学员可以参加" prop="examNumber">
                  <el-input-number @change="numberChange($event,'examNumber')" v-model="examinationConfigInfo.examNumber" size="mini" :precision="0" :controls="false" :min="1"></el-input-number>
                  次考试 取最后一次考试成绩
                </el-form-item>
                <el-form-item label="重新考试试卷" prop="newExam">
                  <el-radio-group v-model="examinationConfigInfo.newExam">
                    <el-radio label="0">原试卷</el-radio>
                    <el-radio label="1">随机试卷</el-radio>
                  </el-radio-group>
                  (仅限该场考试配置了多套试卷)
                </el-form-item>
                <el-form-item label="交卷时显示考试分数" prop="submitScore">
                  <el-switch v-model="examinationConfigInfo.submitScore" active-color="#13ce66" inactive-color="#ff4949" />
                  试卷全部为客观题时，此项配置生效，有主观题则不显示分数
                </el-form-item>
                <el-form-item label="考试前考生承诺书" prop="commitment">
                  <el-switch v-model="examinationConfigInfo.commitment" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
                <el-form-item label="承诺书内容" v-if="examinationConfigInfo.commitment" prop="commitmentContent">
                  <el-input type="textarea" rows="8" resize="none" maxlength="1000" show-word-limit placeholder="请输入承诺书内容" v-model="examinationConfigInfo.commitmentContent">
                  </el-input>
                </el-form-item>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="flex">
                <h3>防作弊设置</h3>
                <el-form-item label="考试前身份核验" prop="checkBeforeExam">
                  <el-switch v-model="examinationConfigInfo.checkBeforeExam" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
                <template v-if="examinationConfigInfo.checkBeforeExam">
                  <el-form-item label="验证方式" prop="checkType">
                    <el-radio-group v-model="examinationConfigInfo.checkType">
                      <el-radio label="0">公安身份认证</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="学员认证" prop="checkFailNumber">
                    <el-input-number @change="numberChange($event,'checkFailNumber')" v-model="examinationConfigInfo.checkFailNumber" size="mini" :precision="0" :controls="false" :min="1" :max="2"></el-input-number>
                    次，认证失败可申请人工审核
                  </el-form-item>
                </template>
                <el-form-item label="考试后锁定屏幕" prop="lockAfterExam">
                  <el-switch v-model="examinationConfigInfo.lockAfterExam" active-color="#13ce66" inactive-color="#ff4949" />
                  <template v-if="examinationConfigInfo.lockAfterExam">
                    切屏
                    <el-input-number @change="numberChange($event,'cutScreenSubmit')" v-model="examinationConfigInfo.cutScreenSubmit" size="mini" :precision="0" :controls="false" :min="1"></el-input-number>
                    次后自动交卷
                  </template>
                </el-form-item>
                <el-form-item label="答题过程中" prop="f12Allow">
                  <el-select style="display: inline-block!important;" v-model="examinationConfigInfo.f12Allow" placeholder="请选择">
                    <el-option label="允许" :value="true" />
                    <el-option label="禁止" :value="false" />
                  </el-select>
                  使用复制、粘贴、F5刷新、F12开发者模式
                </el-form-item>
                <el-form-item label="限定登录IP" prop="ipCheck">
                  <el-switch v-model="examinationConfigInfo.ipCheck" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
                <el-form-item v-if="examinationConfigInfo.ipCheck" prop="ipAddress">
                  <el-input type="textarea" rows="3" resize="none" show-word-limit placeholder="请输入IP地址" v-model="examinationConfigInfo.ipAddress">
                  </el-input>
                  请输入IP地址，以逗号分开，最多允许输入5个<br>
                  例:192.0.0.0,192.1.1.1
                </el-form-item>
              </div>
            </el-form>
          </el-main>
          <el-footer>
            <el-button @click="$router.back()">取消</el-button>
            <el-button style="margin-left: 40px;" type="primary" class="bgc-bv" @click="submitConfig()">保存</el-button>
          </el-footer>
        </el-container>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "examination_onlineTest_examinationConfig",
  components: {
  },
  data () {
    return {
      examinationId: '',
      examinationInfo: {},//试卷信息
      examinationConfigInfo: {
        passScore: ''
      },//配置信息
      rules: {
        commitmentContent: [
          { required: true, message: "请输入承诺书内容", trigger: "blur" },
        ],
        ipAddress: [
          { required: true, message: "请输入IP地址", trigger: "blur" },
        ],
      },
    };
  },
  created () {
    this.examinationId = this.$route.query.examinationId
    this.getInfo()
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    getInfo () {
      let url_1 = "";
      // 运营
      if(['1','-1'].includes(this.userJson.roleId)){
        url_1 = "/run/examination/config";
      } else {
        url_1 = "/biz/examination/getInfo";
      }
      Promise.all([
        this.$post(url_1, { examinationId: this.examinationId }, 3000, true, 6),
        this.$post('/biz/examination/config/getInfo', { examinationId: this.examinationId }, 3000, true, 6)
      ])
        .then((res) => {
          this.examinationInfo = res[0].data
          this.examinationConfigInfo = res[1].data
        })
    },
    numberChange (val, key) {
      if (val === undefined) {
        this.examinationConfigInfo[key] = null
      }
    },
    submitConfig () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.examinationConfigInfo.ipCheck) {
            const arr = this.examinationConfigInfo.ipAddress.split(',')
            if (arr.length > 5) {
              this.$message.error('IP地址最多填写5个！')
              return
            }
          }
          this.$post('/biz/examination/config/modify', this.examinationConfigInfo, 3000, true, 6)
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.back()
                }, 300);
              }
            })
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.operationControl {
  border-bottom: none;
  .searchBox {
    padding: 0 !important;
    .el-icon-arrow-down:before {
      content: '\e6df' !important;
    }
  }
  .searchboxItem {
    margin-right: 30px;
  }
}
.el-main {
  display: flex;
  flex-direction: initial;
  > .el-form {
    display: flex;
    flex-direction: initial;
    flex: 1;
    > .flex {
      width: 48%;
      h3 {
        margin-bottom: 15px;
      }
    }
    .el-divider--vertical {
      height: 100%;
      margin: 0 2%;
    }
  }
}
.el-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-button {
  padding: 12px 40px;
}
.el-input-number--mini {
  width: 70px;
}
.el-switch:not(.no) {
  margin-right: 20px;
}
.el-select {
  /deep/ .el-input {
    width: 80px;
  }
}
</style>
